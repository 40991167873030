var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tabs is-boxed is-centered" }, [
    _c(
      "ul",
      [
        _vm._l(_vm.items, function (item) {
          return _c(
            "li",
            { class: { "is-active": item.role[0] == _vm.isActive } },
            [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("clickTab", item.role[0])
                    },
                  },
                },
                [_vm._m(0, true), _c("span", [_vm._v(_vm._s(item.role[1]))])]
              ),
            ]
          )
        }),
        _c("li", [
          _c("a", { attrs: { href: _vm.new_role_path() } }, [
            _vm._m(1),
            _c("span", [_vm._v("役割追加")]),
          ]),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-user", attrs: { "aria-hidden": "true" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-plus", attrs: { "aria-hidden": "true" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }