<template lang="pug">
.main
  .field.is-horizontal
    .field-label.is-small
      label.label 大学運動部
    .field-body
      .field
        .control
          p.control.has-icons-right
            v-select(v-model="selected"  multiple :options="items" :reduce="label => label.code" label="label" autocomplete="off" placeholder="応援してる大学の運動部を選んでください。")
            input(type="hidden" name="addclubs" :value="selected")
  .border-list
    template(v-for="(item, key) in selectedItem(selected)")
      .border-list-item
        .border-list_inner
          .border-list_title
            span {{ item.label }}
          .border-list_footer
            .field.is-grouped.is-grouped-multiline
              .control
                .tags.has-addons
                  span.tag.is-primary 所在地
                  span.tag.is-light {{ item.location }}
        a.border-list_icon(@click="removeItem(item.code)")
          span.is-medium.is-primary
            i.fas.fa-times-circle
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      items: [],
      selected: [],
      jsonPath: (()=> {
        return '/account/register/fan/select_fan_clubs.json'
      })()
    }
  },
  computed: {},
  created() {
    this.load()
  },
  methods: {
    selectedItem(selected) {
      return this.items.filter(f => selected.includes(f.code));
    },
    removeItem(code){
      const index = this.selected.lastIndexOf(code)
      const removedItem = this.selected.splice(index, 1);
    },
    load() {
      axios.get(this.jsonPath)
        .then((response) => {
          this.items = response.data.result.list_clubs
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }
}
</script>