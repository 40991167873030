<template lang="pug">
.modal#my-modal(:class="{'is-active': isOpen}")
  .modal-background
  .modal-card
    slot
</template>
<style lang="scss" scoped>
</style>
<script>
export default {
  props: ['isOpen'],
  data() {
    return {}
  }
}
</script>