<template lang="pug">
.tab-contents
  .field
      label.label 生年月日
      p.control {{ uup.birth_date }}
  .field
     label.label 国籍
     p.control {{ uup.country }}
  .field
    label.label 身長
    p.control {{ uup.height }}
  .field
    label.label 体重
    p.control {{ uup.weight }}
  .field
    label.label 血液型
    p.control {{ uup.boold }}
  .field
    label.label 出身高校
    p.control {{ uup.high_school }}
  .field
    label.label 大学
    p.control {{ uu.university_name }}
  .field
    label.label 学科・学部
    p.control {{ uu.faculty_name }}
  .field
    label.label 入学年月日
    p.control {{ uup.enrollment_date }}
  .field
    label.label 学年
    p.control {{ uup.school_year }}年生
  .field
    label.label 役職
    p.control {{ uup.title }}
  .field
    label.label 住所
    p.control {{ uup.address_zipcode }}
    p.control {{ uup.location }}
  .field
    label.label 電話番号
    p.control {{ uup.tel }}
  h2.title.is-size-5
    | 緊急連絡先
  .field
    label.label 氏名
    p.control {{ uup.emergency_name }}
  .field
    label.label 続柄
    p.control {{ uup.emergency_relationship }}
  .field
    label.label 電話番号
    p.control {{ uup.emergency_tel }}
  .content-devider
</template>
<script>
export default {
  data() {
    return {
      uup: {},
      uu: {}
    };
  },
  created() {
    const uu = sharedData.univas_user;
    if (uu) {
      this.uu = uu;
    }
    const uup = sharedData.univas_user_profile;
    if (uup) {
      this.uup = uup;
    }
  },
  methods: {}
};
</script>
