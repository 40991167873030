<template lang="pug">
commonModal(:isOpen='termModalStatus')
  header.modal-card-head
    p.modal-card-title Our UNIVAS利用規約
    button.delete(@click="closeModal")
  section.modal-card-body
    p 本利用規約（以下「本規約」と言います。）には、一般社団法人大学スポーツ協会（以下「当協会」といいます。）が提供するサービス「Our UNIVAS」（以下「本サービス」といいます。）の提供条件及び当協会と本サービスを利用する大学、大学運動部、競技団体の皆様（以下「利用団体」といいます。）との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。
    p なお、本サービスにおいてご利用いただけるサービスには、追加のご登録手続が必要となることがあります。この場合には、当該サービスのご利用時に別途提示される利用規約等をご確認いただき、追加のご登録手続をお願いいたします。

    h2.is-size-6 第1条（適用）
    ol
      li 本規約は、本サービスの提供条件及び本サービスの利用に関する当協会と利用団体との間の権利義務関係を定めることを目的とし、当協会と利用団体との間の本サービスの利用に関わる一切の関係に適用されます。
      li 本規約の内容と、本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。

    h2.is-size-6 第2条（本サービスの内容）
    p 本サービスは、当協会が提供するサービス「My UNIVAS」の会員基盤上で、利用団体の運営における事務作業の軽減、利用団体が保有する学生の個人情報の保護、利用団体の広報・情報発信の支援、利用団体の組織ガバナンス向上への寄与等を通じ、利用団体による安全な組織運営、競技の普及・発展を支援するサービスです。

    h2.is-size-6 第3条（利用登録）
    ol
      li 利用団体が本規約に同意のうえ、本サービスの利用申請書を当協会に提出し、所定の登録手続を完了すると、当協会と利用団体との間で本規約の定めを内容とする契約（以下「本契約」といいます。）が成立します。
      li 当協会は、登録を希望する利用団体が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
        ol
          li 当協会に提供した情報の全部または一部につき虚偽、誤記または記載漏れがあった場合
          li 反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当協会が判断した場合
          li 過去に当協会との契約に違反した者またはその関係者であると当協会が判断した場合
          li 第9条に定める措置を受けたことがある場合
          li その他、登録を適当でないと当協会が判断した場合
      li 利用登録は真実かつ正確な情報をもって行ってください。当協会は、当該情報に虚偽、誤り、または記入漏れがあったことにより利用団体に損害が生じたとしても、一切の責任を負わないものとします。

    h2.is-size-6 第4条（ID及びパスワードの管理）
    p アカウントを利用するためのID及びパスワードは、他人に知られることがないよう定期的に変更する等、利用団体が責任をもって管理してください。当協会は、入力または利用されたID及びパスワードの組合せが利用団体の登録したものと一致することを所定の方法により確認した場合、利用団体による利用があったものとみなします。当協会は、盗用、不正利用その他の事情により利用団体のアカウントを当該利用団体以外の第三者が利用している場合であっても、それにより生じた損害について責任を負わないものとします。

    h2.is-size-6 第5条（登録情報の変更）
    p 利用団体として登録した情報（以下「登録情報」といいます。）に変更が生じた場合は、速やかに登録情報の変更をお願いいたします。登録情報の変更がなされなかったことにより利用団体に生じた損害について、当協会は責任を負わないものとします。

    h2.is-size-6 第6条（当協会によるお知らせ）
    ol
      li 当協会から利用団体への通知等は、最新の登録情報におけるメールアドレス宛てへのメールの送信、当協会が運営するウェブサイト上への掲示、または、その他当協会が適当と判断する方法によって行います。
      li 利用団体は、当協会がメールの送信による通知を行った場合、前項のメールアドレス宛てに当協会がメールを送信し利用団体が当該メールを閲覧可能になった時または当協会がメールを送信してから24時間後のいずれか早い時点に当協会からの通知が到達したとみなされることに、あらかじめ同意するものとします。

    h2.is-size-6 第7条（個人情報の取扱い）
    ol
      li
        | 当協会は、利用団体による本サービスの利用に関して取得する個人情報を、当協会の個人情報保護方針（
        a(href="https://www.univas.jp/privacy/" target="_blank")
          | https://www.univas.jp/privacy/
        | ）に従い、適切に取り扱います。
      li 利用団体は、本サービスの利用に関して自らに所属するメンバーその他の第三者の個人情報を取り扱うにあたり、取得に際して本人に対し利用目的を通知または公表すること、個人データを正確かつ最新の内容に保つこと、滅失または毀損の防止その他の個人データの安全管理のために必要かつ適切な措置を講じること、当協会への個人データの提供についての本人からの同意取得その他の必要な対応を行うこと等、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の関係法令及びガイドライン等（以下「個人情報保護法等」といいます。）の規定を遵守するものとします。
      li 利用団体に、利用団体が加盟する中央学生競技団体、地区学生競技団体その他の上位組織（以下「上位組織」といいます。）が存在する場合は、利用団体は、利用団体が本サービスにおいて登録した情報を、上位組織が閲覧、複写、修正、削除その他のアクセスをすることができることについてあらかじめ了承し、これに伴い個人情報保護法等において本人からの同意取得その他の対応が必要となる場合には、本サービスへの情報の登録に先立ってこれを行うものとします。

    h2.is-size-6 第8条（禁止事項）
    ol
      li 本サービスの利用に際して、利用団体は、以下の各号に定める行為またはそのおそれのある行為を行ってはならないものとします。
        ol
          li 法令、裁判所の判決、決定若しくは命令、または法令上拘束力のある行政措置に違反する行為
          li 公の秩序または善良の風俗を害する行為
          li 反社会的勢力に対する利益供与その他の協力行為
          li 当協会、当協会の加盟大学若しくは加盟競技団体、または他の利用団体その他の第三者の権利、利益、名誉等を侵害する行為
          li 第三者になりすます行為または意図的に虚偽の情報を送信する行為
          li 第三者の個人情報その他のプライバシーに関する情報を不正に収集、開示、または提供する行為
          li 不正アクセス行為、第三者のアカウントを利用する行為、複数のアカウントを作成しまたは保有する行為、その他これらに類する行為
          li 本サービスの誤作動を誘引する行為その他本サービスの運営を妨害する行為
          li 本サービスが通常意図しないバグを利用する動作を生じさせ、または、通常意図しない効果を及ぼす外部ツールの利用、作成、または頒布を行う行為
          li 本サービスまたは当協会のサーバーに過度の負担をかける行為
          li コンピュータウイルス等の有害なプログラムを当協会または第三者に送信し、または流布する行為
          li 本規約に違反し、または、本サービスの趣旨目的に反すると当協会が判断する行為
          li その他、当協会が不適切と判断する行為
      li 利用団体は、アカウント及び本契約の地位等の一切の権利及び義務を第三者に譲渡、貸与、売却、担保差入及びその他の処分をしてはならないものとします。

    h2.is-size-6 第9条（利用停止、利用資格の取り消し等）
    ol
      li 当協会は、利用団体が次の各号のいずれかに該当すると判断した場合には、事前に通知することなく当該利用団体による本サービスの利用停止、アカウントの停止、ID及びパスワードの変更、または当該利用団体の利用資格の取消し等の措置を講じることができるものとします。これにより利用団体に何らかの不利益または損害が生じたとしても、当協会は責任を負わないものとします。
        ol
          li 利用団体に法令または本規約に違反する行為があった場合
          li 利用団体に本サービス利用に関して不正行為があった場合
          li 一定回数以上のパスワードの入力ミスがあるなど利用団体のセキュリティを確保するために必要な場合
          li 長期間にわたって本サービスの利用がなく、利用継続の意思がないと当協会が判断した場合
          li 当協会からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合
          li その他当協会が相当と判断した場合
      li 前項のほか、利用団体が当協会の定める一定の期間内に一定回数のログインを行わなかった場合は、当協会は、事前に通知することなく前項所定の措置を講じることができるものとします。

    h2.is-size-6 第10条（利用団体の退会及び本契約の終了）
    ol
      li 利用団体は、当協会所定の退会手続により、いつでも本契約を終了させることができます。
      li 前項の退会手続が完了した場合、または、事由のいかんを問わず本契約が終了した場合、利用団体は、アカウント及び本サービスを即時に一切利用できなくなります。当該時点において利用団体が本サービスに関連して有していた権利は全て消滅し、利用団体は、当協会に対して何らの請求を行えません。但し、本契約終了以前において利用団体が当協会に対して債務を負っていた場合、当該債務は消滅せず、利用団体は、当協会に対して本契約終了後ただちに全ての当該債務について弁済しなければならないものとします。

    h2.is-size-6 第11条（本サービスの変更、中断、終了等）
    ol
      li 当協会は、利用団体に事前に通知することなく、本サービスの内容の全部または一部をその裁量によって変更または追加することができるものとします。
      li 当協会は、事前に第6条所定の方法にて利用団体に通知することにより、当協会の裁量で本サービスの提供を終了することができるものとします。
      li 当協会は、以下の各号に定める場合、利用団体に事前に通知することなく、本サービスの全部または一部を一時的に中断することができるものとします。
        ol
          li システムのメンテナンスまたは修理を定期的にまたは緊急に行う場合
          li アクセス過多等によって、システムに負荷が集中した場合
          li 利用団体、当協会または第三者のセキュリティを確保する必要が生じた場合
          li 通信回線の停止、天災、火災、停電、その他の不慮の事故または戦争、紛争、動乱、暴動、労働争議、感染症その他の疫病の流行の不可抗力により本サービスの提供が困難な場合
          li その他当協会が必要と判断した場合
      li 当協会は、当協会が本条に従ってとった措置に起因して利用団体に生じた損害について、責任を負わないものとします。

    h2.is-size-6 第12条（保証の否認及び免責）
    ol
      li 利用団体は、当協会が本サービスその他利用団体のアカウントで利用できるサービスを通じて提供するコンテンツその他一切の情報について、エラー、バグ、不具合またはセキュリティ上の欠陥が存在しないこと、第三者の権利を侵害しないこと、利用団体が期待する性質を有すること及び利用団体に適用ある法令に適合的であることについて、当協会がいかなる保証も行わないこと、ならびに、本サービスが本サービス提供時における現状有姿において提供されることを理解し、これを承諾するものとし、自己の責任において本サービスを利用するものとします。
      li 当協会は、本サービスその他利用団体のアカウントで利用できるサービスに関する内容の真偽、正確性、最新性、有用性、信頼性、適法性、及び第三者の権利の不侵害について、本規約においていかなる保証も行わないものとし、当該サービスの利用に関して損害が生じた場合であっても、当協会は、一切の責任を負わないものとします。
      li 本サービスの利用に関連して、利用団体が他の利用団体もしくは第三者に対して損害を与えた場合、または利用団体と他の利用団体もしくは第三者との間に紛争が生じた場合、当協会は一切責任を負わないものとし、当該利用団体は自己の費用と責任でかかる損害を賠償し、または、かかる紛争を解決するものとし、かつ当協会に何らの損害・負担を与えないよう適切な措置を講じなければならないものとします。
      li 当協会は、本サービスに登録されたデータについて、安全な管理に努めますが、利用団体は、当該データを自らの責任においてバックアップするものとします。当該バックアップを怠ったことによって利用団体が被った損害について、当協会は、データの復旧を含めて、一切責任を負いません。当協会は、システムセキュリティ上の理由等により、一時的にバックアップを実施する場合がありますが、当該バックアップは、利用団体のデータの保全を目的とするものではなく、当協会は、当該データの完全性、正確性、最新性等を含めて、何ら保証しないものとします。
      li 当協会は、利用団体による本サービスその他利用団体のアカウントで利用できるサービスの利用に関連して、利用団体に対する責任を負う場合には、当協会の故意または重大な過失による場合を除き、利用団体に現実に生じた通常かつ直接の範囲の損害に限り、これを賠償するものとし、特別な事情から生じた損害（損害の発生を予見し、または、予見し得た場合を含みます。）については、責任を負わないものとします。

    h2.is-size-6 第13条（権利帰属）
    p 当協会ウェブサイト及び本サービスに関する知的財産権は全て当協会または当協会にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当協会ウェブサイトまたは本サービスに関する当協会または当協会にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。

    h2.is-size-6 第14条（本規約の改定等）
    ol
      li 当協会は、本規約を任意にいつでも改定することができるものとし、本規約に追加の規定、条件等を定めることができます。本規約を変更する場合、変更後の本規約の施行時期及び内容を当協会ウェブサイト上での掲示その他の適切な方法により周知し、または通知します。但し、法令上利用団体の同意が必要となるような内容の変更の場合は、当協会所定の方法で利用団体の同意を得るものとします。
      li 当協会は、本サービスにかかる事業を第三者に譲渡し、または合併等により承継させ、本契約上の地位ならびに権利及び義務を当該譲渡等の譲受人等に承継させることができるものとします。利用団体は、かかる譲渡等について、あらかじめ同意するものとします。

    h2.is-size-6 第15条（準拠法、合意管轄）
    p 本規約は日本法に基づき解釈されるものとし、本規約に関して生じた一切の紛争については、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
    p.has-text-right 以上
    p 【2020年3月23日制定】
  footer.modal-card-foot
    button.button.is-primary(name="dashboard_term_status" value="1" type="submit") 規約に同意する
</template>
<script>
import commonModal from "../common/modal.vue";
export default {
  components: {
    commonModal
  },
  data() {
    return {
      termModalStatus: false
    };
  },
  async created() {
    this.termModalStatus = !sharedData.my_dashboard_term_status;
  },
  methods: {
    closeModal: function() {
      this.termModalStatus = false;
    }
  }
};
</script>
