var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", { staticClass: "title is-size-4" }, [
        _vm._v("プロフィール詳細"),
      ]),
      _c("div", { staticClass: "level is-mobile" }, [
        _vm._m(0),
        _c("div", { staticClass: "level-right" }, [
          _c("div", { staticClass: "level-item" }, [
            _c(
              "a",
              {
                staticClass: "button is-small",
                attrs: { href: _vm.editUrl(_vm.uu.entity_id) },
              },
              [_c("span", [_vm._v("編集")]), _vm._m(1)]
            ),
          ]),
        ]),
      ]),
      _c("span", { staticClass: "is-size-7" }, [
        _vm._v(_vm._s(_vm.uu.name_kana) + " "),
      ]),
      !!this.uu.name_eng.trim()
        ? _c("span", { staticClass: "is-size-7" }, [
            _vm._v("(" + _vm._s(_vm.uu.name_eng) + ")"),
          ])
        : _vm._e(),
      _c("h2", { staticClass: "title is-size-4" }, [
        _vm._v(_vm._s(_vm.uu.name)),
        _vm.uu.gender === "男性"
          ? _c("span", { staticClass: "tag is-info" }, [_vm._v("男性")])
          : _vm.uu.gender === "女性"
          ? _c("span", { staticClass: "tag is-danger" }, [_vm._v("女性")])
          : _vm.uu.gender === "その他"
          ? _c("span", { staticClass: "tag is-danger" }, [_vm._v("その他")])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "field" }, [
        _c("label", { staticClass: "label" }, [_vm._v("メールアドレス")]),
        _c("p", { staticClass: "control" }, [_vm._v(_vm._s(_vm.uu.email))]),
      ]),
      _c("div", { staticClass: "field" }, [
        _c("label", { staticClass: "label" }, [_vm._v("電話番号")]),
        _c("p", { staticClass: "control" }, [_vm._v(_vm._s(_vm.uup.tel))]),
      ]),
      _c("tabs", {
        attrs: { items: _vm.items, isActive: _vm.isActive },
        on: { clickTab: _vm.onClickTab },
      }),
      _c("tabsContent", { attrs: { isActive: _vm.isActive } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "level-left" }, [
      _c("div", { staticClass: "level-item" }, [
        _c("h2", { staticClass: "title is-size-5" }, [_vm._v("基本情報")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-edit" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }