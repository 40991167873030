<template lang="pug">
.border-list
  h2.title.is-size-5
    | 所属組織
  a.border-list-item(href="/organization/view")
    .border-list_inner
      .border-list_title
        span
          | UNIVAS北海道支部
      .border-list_footer
        .field.is-grouped.is-grouped-multiline
          .control
            .tags.has-addons
              span.tag.is-primary 代表
              span.tag.is-light 鈴木 太郎
          .control
            .tags.has-addons
              span.tag.is-primary 所在地
              span.tag.is-light 北海道
    .border-list_icon
      span.is-medium.is-primary
        i.fas.fa-chevron-right aria-hidden="true"
  a.border-list-item(href="/organization/view")
    .border-list_inner
      .border-list_title
        span
          | UNIVAS関西支部
      .border-list_footer
        .field.is-grouped.is-grouped-multiline
          .control
            .tags.has-addons
              span.tag.is-primary 代表
              span.tag.is-light 鈴木 太郎
          .control
            .tags.has-addons
              span.tag.is-primary 所在地
              span.tag.is-light 大阪
    .border-list_icon
      span.is-medium.is-primary
        i.fas.fa-chevron-right aria-hidden="true"
  .buttons.has-addons.is-centered
    a.button
      span 所属組織をもっと見る
      span.icon
        i.fas.fa-chevron-right        
</template>
<script>
import axios from 'axios'
import axiosJsonpAdapter from 'axios-jsonp'
import jpPrefecture from 'jp-prefecture';

export default {
  props: ['items'],
  data() {
    return {}
  },
  method: {
    gatherResult: () => {
      return 'xxxx'
    }

  },
  created() {

  },
  mounted(){
  },
  methods: {
  }
}
</script>