<template lang="pug">

div
  h1.title.is-size-4
    | プロフィール詳細
  .level.is-mobile
    .level-left
      .level-item
        h2.title.is-size-5
          | 基本情報
    .level-right
      .level-item
        a.button.is-small(:href="editUrl(uu.entity_id)")
          span 編集
          span.icon.is-small
            i.fas.fa-edit
  span.is-size-7 {{ uu.name_kana }}
   | &nbsp;
  span.is-size-7(v-if="!!this.uu.name_eng.trim()") ({{uu.name_eng}})
  h2.title.is-size-4 {{ uu.name }}
    span.tag.is-info(v-if="uu.gender==='男性'") 男性
    span.tag.is-danger(v-else-if="uu.gender==='女性'") 女性
    span.tag.is-danger(v-else-if="uu.gender==='その他'") その他
  .field
     label.label メールアドレス
     p.control {{uu.email}}
  .field
     label.label 電話番号
     p.control {{uup.tel}}
  tabs(:items="items",:isActive="isActive" @clickTab="onClickTab")
  tabsContent(:isActive="isActive")
//showMemberRegisteredOrg
</template>
<script>
import tabs from "../common/tabs.vue";
import tabsContent from "../common/tabsContent";
import showMemberRegisteredOrg from "./showMemberRegisteredOrg.vue";

import Repository from "../../repository/Repository";
export default {
  components: {
    tabs,
    tabsContent,
    showMemberRegisteredOrg
  },
  data() {
    return {
      uu: {},
      uup: {},
      allMyRolesOnlyName: [],
      isActive: "",
      items: [],
      basicInfo: {},
      editUrl: _ => {
        return `/account/members/${_}/edit/`;
      }
    };
  },

  computed: {},
  async created() {
    const uu = sharedData.univas_user;
    if (uu) {
      this.uu = uu;
    }
    const uup = sharedData.univas_user_profile;
    if (uup) {
      this.uup = uup;
    }
    const allMyRoles = sharedData.all_my_roles;
    const allMyRolesOnlyName = sharedData.all_my_roles_only_name;
    if (allMyRoles) {
      this.items = allMyRoles;
      this.allMyRolesOnlyName = allMyRolesOnlyName;
      this.isActive = this.allMyRolesOnlyName[0];
    }
  },
  methods: {
    onClickTab(childMsg) {
      this.isActive = childMsg;
    }
  }
};
</script>
