var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-contents" }, [
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("生年月日")]),
      _c("p", { staticClass: "control" }, [_vm._v(_vm._s(_vm.uup.birth_date))]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("国籍")]),
      _c("p", { staticClass: "control" }, [_vm._v(_vm._s(_vm.uup.country))]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("身長")]),
      _c("p", { staticClass: "control" }, [_vm._v(_vm._s(_vm.uup.height))]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("体重")]),
      _c("p", { staticClass: "control" }, [_vm._v(_vm._s(_vm.uup.weight))]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("血液型")]),
      _c("p", { staticClass: "control" }, [_vm._v(_vm._s(_vm.uup.boold))]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("出身高校")]),
      _c("p", { staticClass: "control" }, [
        _vm._v(_vm._s(_vm.uup.high_school)),
      ]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("大学")]),
      _c("p", { staticClass: "control" }, [
        _vm._v(_vm._s(_vm.uu.university_name)),
      ]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("学科・学部")]),
      _c("p", { staticClass: "control" }, [
        _vm._v(_vm._s(_vm.uu.faculty_name)),
      ]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("入学年月日")]),
      _c("p", { staticClass: "control" }, [
        _vm._v(_vm._s(_vm.uup.enrollment_date)),
      ]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("学年")]),
      _c("p", { staticClass: "control" }, [
        _vm._v(_vm._s(_vm.uup.school_year) + "年生"),
      ]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("役職")]),
      _c("p", { staticClass: "control" }, [_vm._v(_vm._s(_vm.uup.title))]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("住所")]),
      _c("p", { staticClass: "control" }, [
        _vm._v(_vm._s(_vm.uup.address_zipcode)),
      ]),
      _c("p", { staticClass: "control" }, [_vm._v(_vm._s(_vm.uup.location))]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("電話番号")]),
      _c("p", { staticClass: "control" }, [_vm._v(_vm._s(_vm.uup.tel))]),
    ]),
    _c("h2", { staticClass: "title is-size-5" }, [_vm._v("緊急連絡先")]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("氏名")]),
      _c("p", { staticClass: "control" }, [
        _vm._v(_vm._s(_vm.uup.emergency_name)),
      ]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("続柄")]),
      _c("p", { staticClass: "control" }, [
        _vm._v(_vm._s(_vm.uup.emergency_relationship)),
      ]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("電話番号")]),
      _c("p", { staticClass: "control" }, [
        _vm._v(_vm._s(_vm.uup.emergency_tel)),
      ]),
    ]),
    _c("div", { staticClass: "content-devider" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }