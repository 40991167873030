var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main" }, [
    _c("div", { staticClass: "field is-horizontal" }, [
      _vm._m(0),
      _c("div", { staticClass: "field-body" }, [
        _c("div", { staticClass: "field" }, [
          _c("div", { staticClass: "control" }, [
            _c(
              "p",
              { staticClass: "control has-icons-right" },
              [
                _c("v-select", {
                  attrs: {
                    multiple: "",
                    options: _vm.items,
                    reduce: (label) => label.code,
                    label: "label",
                    autocomplete: "off",
                    placeholder: "応援してる大学の運動部を選んでください。",
                  },
                  model: {
                    value: _vm.selected,
                    callback: function ($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected",
                  },
                }),
                _c("input", {
                  attrs: { type: "hidden", name: "addclubs" },
                  domProps: { value: _vm.selected },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "border-list" },
      [
        _vm._l(_vm.selectedItem(_vm.selected), function (item, key) {
          return [
            _c("div", { staticClass: "border-list-item" }, [
              _c("div", { staticClass: "border-list_inner" }, [
                _c("div", { staticClass: "border-list_title" }, [
                  _c("span", [_vm._v(_vm._s(item.label))]),
                ]),
                _c("div", { staticClass: "border-list_footer" }, [
                  _c(
                    "div",
                    { staticClass: "field is-grouped is-grouped-multiline" },
                    [
                      _c("div", { staticClass: "control" }, [
                        _c("div", { staticClass: "tags has-addons" }, [
                          _c("span", { staticClass: "tag is-primary" }, [
                            _vm._v("所在地"),
                          ]),
                          _c("span", { staticClass: "tag is-light" }, [
                            _vm._v(_vm._s(item.location)),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c(
                "a",
                {
                  staticClass: "border-list_icon",
                  on: {
                    click: function ($event) {
                      return _vm.removeItem(item.code)
                    },
                  },
                },
                [_vm._m(1, true)]
              ),
            ]),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "field-label is-small" }, [
      _c("label", { staticClass: "label" }, [_vm._v("大学運動部")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "is-medium is-primary" }, [
      _c("i", { staticClass: "fas fa-times-circle" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }