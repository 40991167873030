var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab-contents" },
    [
      _c("h2", { staticClass: "title is-size-5" }, [_vm._v("役割情報")]),
      _vm.isActive === "Student"
        ? _c("showMemberDetailStudent")
        : _c("showMemberDetailOther"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }