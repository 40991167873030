<template lang="pug">
.tab-contents
  .field
    label.label 所属部署
    p.control {{uup.department}}
  .field
    label.label 役職
    p.control {{uup.title}}
  .content-devider
</template>
<script>
import axios from 'axios'
import axiosJsonpAdapter from 'axios-jsonp'

export default {
  props: ['items'],
  data() {
    return {
      uup: {},
      uu: {}
    }
  },
  method: {
  },
  created() {
    const uu = sharedData.univas_user;
    if(uu) { this.uu = uu }
    const uup = sharedData.univas_user_profile;
    if(uup) { this.uup = uup }
  },
  mounted(){
  },
  methods: {
  }
}
</script>
