import axios from 'axios'

export const post = async (apiPath, params) => {
  let response;

  if ( !apiPath ) {
    throw new Error( 'apiPathが設定されていません。' )
  }

  try {
    response = await axios.post(apiPath, {
      ...params
    });
  } catch ( e ) {
    throw new Error( 'API POSTが失敗しました。!' + e.toString() )
  }

  return response.data;
}

export const get = async (
  apiPath,
  params
) => {

  if ( !apiPath ) {
    throw new Error( 'apiPathが設定されていません。' )
  }

  let response;

  try {
    response = await axios( {
      method: 'GET',
      url: apiPath,
      params: params
    } )
  } catch ( e ) {

    throw new Error( 'APIリクエストに失敗しました。!' + e.toString() )
  }

  // --- APIの返却値の正当性確認 start ---
  // 正当な値が返されていることを確認
  if ( !response || !response.data || !( typeof response.data === 'object' ) ) {
    throw new Error( 'APIの返却値が不正です。' )
  }
  // --- APIの返却値の正当性確認 end ---
  return response.data
}


export const axiosGet = ( apiPath, config ) => {
  return new Promise( ( resolve, reject ) => {
    axios( {
      method: 'GET',
      url: apiPath,
      params: {
        agent: 'sp'
      },
      ...config
    } )
      .then( ( res ) => {
        resolve( res.data )
      } )
      .catch( ( e ) => reject( e.toString() ) )
  } )
}

export default {
  get,
  post,
  axiosGet
}
