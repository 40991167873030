<template lang="pug">
.tabs.is-boxed.is-centered
  ul
    li(v-for="item in items", :class="{'is-active': item.role[0] == isActive}")
      a(v-on:click="$emit('clickTab', item.role[0])")
        span.icon.is-small
          i.fas.fa-user(aria-hidden="true")
        span {{ item.role[1] }}
    li
      a(:href="new_role_path()")
        span.icon.is-small
          i.fas.fa-plus(aria-hidden="true")
        span 役割追加
</template>
<script>
export default {
  events: {
    changeActive: () => []
  },
  props: ["items", "isActive"],
  data() {
    return {
      new_role_path: () => {
        return `${location.pathname}/role/new`;
      }
    };
  },
  created() {},
  methods: {}
};
</script>
