var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-contents" }, [
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("所属部署")]),
      _c("p", { staticClass: "control" }, [_vm._v(_vm._s(_vm.uup.department))]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { staticClass: "label" }, [_vm._v("役職")]),
      _c("p", { staticClass: "control" }, [_vm._v(_vm._s(_vm.uup.title))]),
    ]),
    _c("div", { staticClass: "content-devider" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }