var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "border-list" }, [
      _c("h2", { staticClass: "title is-size-5" }, [_vm._v("所属組織")]),
      _c(
        "a",
        {
          staticClass: "border-list-item",
          attrs: { href: "/organization/view" },
        },
        [
          _c("div", { staticClass: "border-list_inner" }, [
            _c("div", { staticClass: "border-list_title" }, [
              _c("span", [_vm._v("UNIVAS北海道支部")]),
            ]),
            _c("div", { staticClass: "border-list_footer" }, [
              _c(
                "div",
                { staticClass: "field is-grouped is-grouped-multiline" },
                [
                  _c("div", { staticClass: "control" }, [
                    _c("div", { staticClass: "tags has-addons" }, [
                      _c("span", { staticClass: "tag is-primary" }, [
                        _vm._v("代表"),
                      ]),
                      _c("span", { staticClass: "tag is-light" }, [
                        _vm._v("鈴木 太郎"),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "control" }, [
                    _c("div", { staticClass: "tags has-addons" }, [
                      _c("span", { staticClass: "tag is-primary" }, [
                        _vm._v("所在地"),
                      ]),
                      _c("span", { staticClass: "tag is-light" }, [
                        _vm._v("北海道"),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "border-list_icon" }, [
            _c("span", { staticClass: "is-medium is-primary" }, [
              _c("i", { staticClass: "fas fa-chevron-right" }, [
                _vm._v('aria-hidden="true"'),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "border-list-item",
          attrs: { href: "/organization/view" },
        },
        [
          _c("div", { staticClass: "border-list_inner" }, [
            _c("div", { staticClass: "border-list_title" }, [
              _c("span", [_vm._v("UNIVAS関西支部")]),
            ]),
            _c("div", { staticClass: "border-list_footer" }, [
              _c(
                "div",
                { staticClass: "field is-grouped is-grouped-multiline" },
                [
                  _c("div", { staticClass: "control" }, [
                    _c("div", { staticClass: "tags has-addons" }, [
                      _c("span", { staticClass: "tag is-primary" }, [
                        _vm._v("代表"),
                      ]),
                      _c("span", { staticClass: "tag is-light" }, [
                        _vm._v("鈴木 太郎"),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "control" }, [
                    _c("div", { staticClass: "tags has-addons" }, [
                      _c("span", { staticClass: "tag is-primary" }, [
                        _vm._v("所在地"),
                      ]),
                      _c("span", { staticClass: "tag is-light" }, [
                        _vm._v("大阪"),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "border-list_icon" }, [
            _c("span", { staticClass: "is-medium is-primary" }, [
              _c("i", { staticClass: "fas fa-chevron-right" }, [
                _vm._v('aria-hidden="true"'),
              ]),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "buttons has-addons is-centered" }, [
        _c("a", { staticClass: "button" }, [
          _c("span", [_vm._v("所属組織をもっと見る")]),
          _c("span", { staticClass: "icon" }, [
            _c("i", { staticClass: "fas fa-chevron-right" }),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }