// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();

import "../src/sass/application";

import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";

import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

import Vue from "vue";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);

import "vue-select/dist/vue-select.css";
import SelectFanClubs from "../src/components/account/selectFanClubs.vue";
//import SelectFormAddress from '../src/components/common/selectFormAddress.vue'
import ShowMembers from "../src/components/account/showMembers.vue";
import termModal from "../src/components/dashboard/termModal.vue";

document.addEventListener("DOMContentLoaded", () => {
  const selectFanClubs = new Vue({
    el: document.getElementById("selectFanClubs"),
    render: h => h(SelectFanClubs)
  });

  //const selectFormAddress = new Vue({
  //  el: document.getElementById('selectFormAddress'),
  //  render: h => h(SelectFormAddress)
  //})

  const showMembers = new Vue({
    el: document.getElementById("vvv-show-members"),
    render: h => h(ShowMembers)
  });

  const element = document.getElementById("vvv-modals");
  const modal = new Vue({
    el: element,
    render: h => h(termModal)
  });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context("../src/images", true);
const imagePath = name => images(name, true);
