<template lang="pug">
.tab-contents
  h2.title.is-size-5
    | 役割情報
  showMemberDetailStudent(v-if="isActive==='Student'")
  showMemberDetailOther(v-else)
</template>
<script>
import showMemberDetailOther from "../account/showMemberDetailOther.vue";
import showMemberDetailStudent from "../account/showMemberDetailStudent.vue";
export default {
  components: {
    showMemberDetailOther,
    showMemberDetailStudent
  },
  props: ["isActive"],
  data() {
    return {};
  },
  created() {},
  methods: {}
};
</script>
